import React from 'react'
import { HiBuildingOffice2, HiCalendarDays, HiClipboardDocumentCheck, HiHome, HiPlusCircle, HiRectangleGroup, HiUserPlus, HiUsers } from 'react-icons/hi2'
import { SiAlltrails } from 'react-icons/si'
import { FaCity, FaMoneyBillWaveAlt } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import FALogo from "../Assets/Images/Logo.png"
import ProfilePic from '../Assets/SVG/Profile.svg'
import { TfiGallery } from 'react-icons/tfi'

const Header = () => {

    const navigate = useNavigate()

    return (
        <React.Fragment>

            <div className="drawer z-50 ">
                <input id="drawer" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content">
                    {/* Page content here */}
                </div>
                <div className="drawer-side">
                    <label htmlFor="drawer" className="drawer-overlay"></label>
                    <div className="relative bg-white">
                        <div className="flex flex-col sm:flex-row sm:justify-around">
                            <div className=" min-h-screen w-72">
                                <nav className="px-6 mt-10">
                                    <Link to={"/"}>
                                        <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100">
                                            <HiRectangleGroup size={24} color='#A55B27' />
                                            <span className="mx-4 font-normal text-md">
                                                Dashboard
                                            </span>
                                        </div>
                                    </Link>
                                    <div>
                                        <p className="w-full pb-2 mb-4 ml-2 font-normal text-gray-300 border-b-2 border-gray-100 text-md">
                                            Permit
                                        </p>
                                        <Link to={"daily_permits_list"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100">
                                                <HiClipboardDocumentCheck size={24} color='#A55B27' />
                                                <span className="mx-4 font-normal text-md">
                                                    Daily Permits List
                                                </span>
                                            </div>
                                        </Link>
                                        <Link to={"yearly_permits_list"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100">
                                                <HiClipboardDocumentCheck size={24} color='#A55B27' />
                                                <span className="mx-4 font-normal text-md">
                                                    Yearly Permits List
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div>
                                        <p className="w-full pb-2 mb-4 ml-2 font-normal text-gray-300 border-b-2 border-gray-100 text-md">
                                            Companies
                                        </p>
                                        <Link to={"companies_list"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100">
                                                <span className="text-left">
                                                    <HiBuildingOffice2 size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Companies List
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div>
                                        <p className="w-full pb-2 mb-4 ml-2 font-normal text-gray-300 border-b-2 border-gray-100 text-md">
                                            Events
                                        </p>
                                        <Link to={"/events_list"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                <span className="text-left">
                                                    <HiCalendarDays size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Partners Events
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div>
                                        <p className="w-full pb-2 mb-4 ml-2 font-normal text-gray-300 border-b-2 border-gray-100 text-md">
                                            Fees
                                        </p>
                                        <Link to={"/fees_list"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                <span className="text-left">
                                                    <FaMoneyBillWaveAlt size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Fees List
                                                </span>
                                            </div>
                                        </Link>
                                        <Link to={"/add_fee"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                <span className="text-left">
                                                    <HiPlusCircle size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Add Fee
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div>
                                        <p className="w-full pb-2 mb-4 ml-2 font-normal text-gray-300 border-b-2 border-gray-100 text-md">
                                            Website
                                        </p>
                                        {
                                            localStorage.getItem("position") === "Super Admin" ?
                                                <Link to={"/staffs_list"}>
                                                    <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                        <span className="text-left">
                                                            <HiUserPlus size={24} color='#A55B27' />
                                                        </span>
                                                        <span className="mx-4 font-normal text-md">
                                                            Staffs List
                                                        </span>
                                                    </div>
                                                </Link>
                                                :
                                                null
                                        }
                                        <Link to={"/main_website"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                <span className="text-left">
                                                    <HiHome size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Main Website
                                                </span>
                                            </div>
                                        </Link>
                                        <Link to={"/registred_users"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                <span className="text-left">
                                                    <HiUsers size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Registred Users
                                                </span>
                                            </div>
                                        </Link>
                                        <Link to={"/local_events"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                <span className="text-left">
                                                    <HiCalendarDays size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Fujairah Events
                                                </span>
                                            </div>
                                        </Link>
                                        <Link to={"/local_cities"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                <span className="text-left">
                                                    <FaCity size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Fujairah Cities
                                                </span>
                                            </div>
                                        </Link>
                                        <Link to={"/local_trails"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                <span className="text-left">
                                                    <SiAlltrails size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Fujairah Trails
                                                </span>
                                            </div>
                                        </Link>
                                        <Link to={"/local_gallery"}>
                                            <div className="flex items-center justify-start p-2 my-6 text-gray-600 transition-colors duration-200 hover:text-gray-800 bg-gray-50 hover:bg-gray-100" href="#">
                                                <span className="text-left">
                                                    <TfiGallery size={24} color='#A55B27' />
                                                </span>
                                                <span className="mx-4 font-normal text-md">
                                                    Fujairah Gallery
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="navbar bg-[#d2b199c6]">
                <div className="flex-none">
                    <label htmlFor="drawer" className="btn btn-square btn-ghost">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                    </label>
                </div>
                <div className="flex-1">
                    <Link to={"/"}>
                        <div className="btn btn-ghost normal-case text-xl">
                            <img src={FALogo} className=' w-full object-cover' />
                        </div>
                    </Link>
                </div>
                <div className="flex-none">
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full object-cover">
                                <img src={ProfilePic} className=' w-full' />
                            </div>
                        </label>
                        <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                            <li>
                                <div onClick={() => { localStorage.removeItem("token"); navigate("/login") }}>Logout</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default Header