import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react';

const DetailsPopUp = ({ url, data, closeModal, showPopUp, type }) => {

    const [showModal, setShowModal] = useState(showPopUp);
    let totalAmounts;

    useEffect(() => {
        setShowModal(showPopUp);
    }, [showPopUp]);

    function calculateTotalAmount(data) {
        if (!Array.isArray(data)) {
            return 0; // Return 0 or another appropriate value if data is not an array
        }

        const totalAmount = data?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.amount;
        }, 0);

        return totalAmount;
    }
    let activityString;
    let authorities;

    if (data && data?.event) {
        const feesList = JSON.parse(data?.event?.feesList);
        const totalAmount = calculateTotalAmount(feesList);
        totalAmounts = totalAmount
        // const activityArray = JSON.parse(data?.event?.activityType);
        const authoritiesArray = JSON.parse(data?.company?.issuingAuthority)
        // const activityTitles = (activityArray).map(activity => activity.title);
        const authorityTitles = (authoritiesArray).map(activity => activity.title);
        // activityString  = activityTitles.join(', ');
        authorities = authorityTitles.join(', ')
    } else {
        console.error('Data or event is null or undefined');
    }


    return (
        <Fragment>
            <Transition appear show={showModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 pb-4"
                                    >
                                        Company
                                    </Dialog.Title>
                                    {
                                        data &&
                                        <div className=' flex flex-col w-full space-y-10'>
                                            <div className=' grid grid-cols-3 gap-5'>
                                                {
                                                    data?.company?.name &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Name</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {data?.company?.name}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    data?.company?.licenseNumber &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>License Number</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {data?.company?.licenseNumber}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    data?.event?.activityType &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Activity Type</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {data?.event?.activityType}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    data?.company?.tradeLicense &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Trade Licence</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            <a href={`${url}${data?.company?.tradeLicense}`} target='_blank'>
                                                                View File
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    data?.company?.issuingAuthority &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Issuing Authority</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {authorities}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    data?.company?.contactNumber &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Contact Number</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {data?.company?.contactNumber}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    data?.company?.contactEmail &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Contact Email</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {data?.company?.contactEmail}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    data?.company?.expirationDate &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Expiration Date</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {moment(data?.company?.expirationDate).format("MMMM Do YYYY")}
                                                        </div>
                                                    </div>
                                                }
                                                <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Permit Type</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        {type}
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 className='text-2xl font-semibold text-left py-4'>Event</h3>
                                            <div className=' grid grid-cols-3 gap-5'>
                                                {
                                                    data?.event?.name &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Name</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {data?.event?.name}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    data?.event?.location &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Location</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {data?.event?.location}
                                                        </div>
                                                    </div>
                                                }
                                                {/* {data?.event?.activityType && <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Activity Type</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        {data?.event?.activityType}
                                                    </div>
                                                </div>} */}
                                                {data?.event?.companyInsurancePolicy && <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Insurance Policy</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        <a href={`${url}${data?.event?.companyInsurancePolicy}`} target='_blank'>
                                                            View File
                                                        </a>
                                                    </div>
                                                </div>}
                                                {data?.event?.acknowledge && <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Acknowledge</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        <a href={`${url}${data?.event?.acknowledge}`} target='_blank'>
                                                            View File
                                                        </a>
                                                    </div>
                                                </div>}
                                                {data?.event?.implementationPlan && <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Implementation Plan</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        <a href={`${url}${data?.event?.implementationPlan}`} target='_blank'>
                                                            View File
                                                        </a>
                                                    </div>
                                                </div>}
                                                {data?.event?.companyInsurancePolicy && <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Company Insurance Policy</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        <a href={`${url}${data?.event?.companyInsurancePolicy}`} target='_blank'>
                                                            View File
                                                        </a>
                                                    </div>
                                                </div>}
                                                {data?.event?.internationalTradeMarkLicense && <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>International Trademark License</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        <a href={`${url}${data?.event?.internationalTradeMarkLicense}`} target='_blank'>
                                                            View File
                                                        </a>
                                                    </div>
                                                </div>}
                                                {data?.event?.riskManagmentDraft && <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Risk Managment Draft</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        <a href={`${url}${data?.event?.riskManagmentDraft}`} target='_blank'>
                                                            View File
                                                        </a>
                                                    </div>
                                                </div>}
                                                {
                                                    type === "One Day" &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Leaders Number</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {data?.leadersNumber}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    type === "One Day" &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Assistances Number</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {data?.assistancesNumber}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <h3 className='text-2xl font-semibold text-left py-4'>Fees List</h3>
                                            <div className=' grid grid-cols-3 gap-5'>
                                                {
                                                    (JSON.parse(data?.event?.feesList)) &&
                                                    (JSON.parse(data?.event?.feesList)).map(fee => (
                                                        <div className=' flex flex-col space-y-2' key={fee.id}>
                                                            <h3 className=' text-left'>Option {fee.id}</h3>
                                                            <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                                {fee.title + " || " + fee.amount + " AED"}
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <h3 className='text-2xl font-semibold text-right mt-6'>{data?.status === "Active" ? "Amount Paid" : "Amount To Pay"}: {totalAmounts} <span className=' text-xs'>AED</span></h3>
                                            <div className="modal-action">
                                                {/* if there is a button, it will close the modal */}
                                                {/* <div className="btn bg-[#4f8e5469] text-[#4f8e54]" onClick={() => { approvePermit(data?.id) }}>Approve</div> */}
                                                <button onClick={() => { closeModal() }} className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                                            </div>
                                        </div>
                                    }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}

export default DetailsPopUp