import axios from 'axios'
import moment from 'moment/moment'
import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import { TiCancel, TiTick } from 'react-icons/ti'
import { HiInformationCircle } from 'react-icons/hi2'
import { useNavigate } from 'react-router'
import DenyPopUp from './DenyPopUp'
import DetailsPopUp from './DetailsPopUp'

const Dasboard = () => {

  const navigate = useNavigate()

  const detailsModelRef = useRef(null);

  const [permitsDataYearly, setPermitsDataYearly] = useState([])
  const [permitsDataDaily, setPermitsDataDaily] = useState([])
  const [usersData, setUsersData] = useState({ users: [], formUsers: [] })
  const [eventsData, setEventsData] = useState([])
  const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)
  const [permitsStats, setPermitsStats] = useState([])
  const [companiesStats, setCompaniesStats] = useState([])
  const [financeStats, setFinanceStats] = useState([])
  const [showDenyPopup, setShowDenyPopup] = useState(false)
  const [showInfoPopup, setShowInfoPopup] = useState(false)
  const [permitId, setPermitId] = useState(null)
  const [companyData, setCompanyData] = useState(null)
  const [permitType, setPermitType] = useState("")

  const getAllAwaitingPermits = () => {
    const token = localStorage.getItem("token")
    axios.get(`permit/year/infos/?token=${token}`)
      .then(res => {
        setPermitsDataYearly(res.data)
        console.log(Array.isArray(res.data))
        console.log((res.data).filter((permit) => permit.approved === false))
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          "Error!",
          `${error.response.data}`,
          "error"
        )
        navigate("/login", { replace: true })
      })
    axios.get(`permit/day/infos/?token=${token}`)
      .then(res => {
        setPermitsDataDaily(res.data)
        console.log((res.data).filter((permit) => permit.approved === false))
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          "Error!",
          `${error.response.data}`,
          "error"
        )
        navigate("/login", { replace: true })
      })
  }

  const getAllUsers = () => {
    const token = localStorage.getItem("token")
    axios.get(`users/infos/?token=${token}`)
      .then(res => {
        setUsersData(res.data)
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          "Error!",
          `${error.response.data}`,
          "error"
        )
        navigate("/login", { replace: true })
      })
  }

  const getAllEvents = () => {
    const token = localStorage.getItem("token")
    axios.get(`permit/events/?token=${token}`)
      .then(res => {
        setEventsData(res.data)
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          "Error!",
          `${error.response.data}`,
          "error"
        )
        navigate("/login", { replace: true })
      })
  }

  const sendApproval = (permitId) => {
    const token = localStorage.getItem("token")
    axios.put(`permit/day/approval/${permitId}/?token=${token}`)
      .then(res => {
        Swal.fire(
          "Success!",
          "Approved with success and email will be sent to the company",
          "success"
        )
        getAllAwaitingPermits()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const approvePermit = (permitId) => {
    Swal.fire({
      title: "Pemit approval",
      html: "Are you sure you want to approve this permit? <br/>" +
        "(An email will be sent to the company email)",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Approve"
    })
      .then((res) => {
        if (res.isConfirmed) {
          sendApproval(permitId)
        } else {
          Swal.fire(
            "Info!",
            "The permit is not approved",
            "info"
          )
        }
      })
  }

  const getStats = () => {
    const token = localStorage.getItem("token")
    axios.get(`permit/stats/month/?token=${token}`)
      .then(res => {
        setPermitsStats(res.data)
      })
      .catch(err => {
        console.log(err)
      })
    axios.get(`permit/companies/stats/month/?token=${token}`)
      .then(res => {
        setCompaniesStats(res.data)
      })
      .catch(err => {
        console.log(err)
      })

    axios.get(`permit/fees/stats/month/?token=${token}`)
      .then(res => {
        setFinanceStats(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const openModal = (state) => {
    state(true);
  };

  const closeModal = (state) => {
    state(false);
  };



  useEffect(() => {
    const timout = setTimeout(() => {
      getAllAwaitingPermits()
      getAllUsers();
      getAllEvents();
      getStats();
    }, 30000);
    return () => {
      clearTimeout(timout);
    }
  }, [getAllAwaitingPermits, getAllUsers, getAllEvents, getStats])

  useEffect(() => {
    getAllAwaitingPermits()
    getAllUsers();
    getAllEvents();
    getStats();
  }, [])



  return (
    <React.Fragment>
      <DenyPopUp showPopUp={showDenyPopup} onClose={() => { closeModal(setShowDenyPopup) }} permitId={permitId} refreshData={getAllAwaitingPermits} />
      <DetailsPopUp data={companyData} url={apiBaseUrl.replace('/api/v1', '')} approvePermit={approvePermit} type={permitType} showPopUp={showInfoPopup} closeModal={() => { closeModal(setShowInfoPopup) }} />
      <div className=' flex flex-col w-screen items-center py-10 space-y-10'>
        {/* Stats Cards */}
        <div className=' grid grid-cols-3 w-10/12 gap-x-5 '>
          <div className="p-4 bg-[#d6a97b71] shadow-lg rounded-2xl">
            <div className="flex items-center">
              <span className="relative p-4 bg-[#856397] rounded-xl">
                <svg width="40" fill="currentColor" height="40" className="absolute h-4 text-[#db97ff] transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                  </path>
                </svg>
              </span>
              <p className="ml-2 text-black text-md">
                Finance
              </p>
            </div>
            <div className="flex flex-col justify-start">
              <p className="my-4 text-4xl font-bold text-left text-gray-700">
                {
                  financeStats.thisMonth ?
                    financeStats.thisMonth + " AED"
                    :
                    0
                }
              </p>
              <div className="flex items-center text-sm">
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" className={`${financeStats.thisMonth - financeStats.pastMonth < financeStats.pastMonth - financeStats.thisMonth ? " rotate-180 text-[#8e4f4f]" : "text-[#4f8e54]"}`}>
                  <path d="M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z">
                  </path>
                </svg>
                <div className={`space-x-1 ${financeStats.thisMonth - financeStats.pastMonth < financeStats.pastMonth - financeStats.thisMonth ? "text-[#8e4f4f]" : "text-[#4f8e54]"}`}>
                  <span>
                    {Math.abs(financeStats.thisMonth - financeStats.pastMonth) + " AED"}
                  </span>
                  <span className="text-black">
                    vs last month
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 bg-[#d6a97b71] shadow-lg rounded-2xl">
            <div className="flex items-center">
              <span className="relative p-4 bg-[#856397] rounded-xl">
                <svg width="40" fill="currentColor" height="40" className="absolute h-4 text-[#db97ff] transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                  </path>
                </svg>
              </span>
              <p className="ml-2 text-black text-md">
                Permits
              </p>
            </div>
            <div className="flex flex-col justify-start">
              <p className="my-4 text-4xl font-bold text-left text-gray-700">
                {
                  permitsStats.thisMonth ?
                    permitsStats.thisMonth
                    :
                    0
                }
              </p>
              <div className="flex items-center text-sm">
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" className={`${permitsStats.thisMonth - permitsStats.pastMonth < permitsStats.pastMonth - permitsStats.thisMonth ? " rotate-180 text-[#8e4f4f]" : "text-[#4f8e54]"}`}>
                  <path d="M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z">
                  </path>
                </svg>
                <div className={`space-x-1 ${permitsStats.thisMonth - permitsStats.pastMonth < permitsStats.pastMonth - permitsStats.thisMonth ? "text-[#8e4f4f]" : "text-[#4f8e54]"}`}>
                  <span>
                    {Math.abs(permitsStats.thisMonth - permitsStats.pastMonth)}
                  </span>
                  <span className="text-black">
                    vs last month
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 bg-[#d6a97b71] shadow-lg rounded-2xl">
            <div className="flex items-center">
              <span className="relative p-4 bg-[#856397] rounded-xl">
                <svg width="40" fill="currentColor" height="40" className="absolute h-4 text-[#db97ff] transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                  </path>
                </svg>
              </span>
              <p className="ml-2 text-black text-md">
                Companies
              </p>
            </div>
            <div className="flex flex-col justify-start">
              <p className="my-4 text-4xl font-bold text-left text-gray-700">
                {
                  companiesStats.thisMonth ?
                    companiesStats.thisMonth
                    :
                    0
                }
              </p>
              <div className="flex items-center text-sm">
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" className={`${companiesStats.thisMonth - companiesStats.pastMonth < companiesStats.pastMonth - companiesStats.thisMonth ? " rotate-180 text-[#8e4f4f]" : "text-[#4f8e54]"}`}>
                  <path d="M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z">
                  </path>
                </svg>
                <div className={`space-x-1 ${companiesStats.thisMonth - companiesStats.pastMonth < companiesStats.pastMonth - companiesStats.thisMonth ? "text-[#8e4f4f]" : "text-[#4f8e54]"}`}>
                  <span>
                    {Math.abs(companiesStats.thisMonth - companiesStats.pastMonth)}
                  </span>
                  <span className="text-black">
                    vs last month
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Stats Cards End */}
        {/* not approved permits */}
        <div className=' flex flex-row w-10/12'>
          <div className=' w-full'>
            <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Pending Yearly Permits</p>
          </div>
        </div>
        <div className="overflow-x-auto w-10/12">
          <table className="table table-zebra">
            <thead>
              <tr>
                <th></th>
                <th>Company Name</th>
                {/* <th>Event Title</th> */}
                <th>Expiration Date</th>
                <th>Submition Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                permitsDataYearly.length > 0 &&
                (permitsDataYearly.slice(0, 10)).filter((permit) => permit.status === "Awaiting").map((permit) => (
                  <tr key={permit.id}>
                    <th>{permit.id}</th>
                    <td>{permit.company.name}</td>
                    <td>{moment(permit.company.expirationDate).format("MMM Do YYYY")}</td>
                    <td>{moment(permit.createdAt).format("MMM Do YYYY")}</td>
                    <td>
                      <div className=' flex flex-row space-x-3' >
                        <div className='tooltip hover:cursor-pointer' data-tip="Permit Details" onClick={() => { setCompanyData(permit); setPermitType("One Year"); openModal(setShowInfoPopup); }}>
                          <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                        </div>
                        <div className='tooltip hover:cursor-pointer' data-tip="Approve Permit" onClick={() => { approvePermit(permit.id) }}>
                          <TiTick size={24} color='#4F8E54' className=' bg-[#4f8e5469] rounded-md' />
                        </div>
                        <div className='tooltip hover:cursor-pointer' onClick={() => { setPermitType("One Year"); setPermitId(permit.id); openModal(setShowDenyPopup) }} data-tip="Deny Permit">
                          <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className=' flex flex-row w-10/12'>
          <div className=' w-full'>
            <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Pending Daily Permits</p>
          </div>
        </div>
        <div className="overflow-x-auto w-10/12">
          <table className="table table-zebra">
            <thead>
              <tr>
                <th></th>
                <th>Company Name</th>
                <th>Event Title</th>
                <th>Expiration Date</th>
                <th>Submition Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                permitsDataDaily.length > 0 &&
                (permitsDataDaily.slice(0, 10)).filter((permit) => permit.status === "Awaiting").map((permit) => (
                  <tr key={permit.id}>
                    <th>{permit.id}</th>
                    <td>{permit.company.name}</td>
                    <td>{permit.event.name}</td>
                    <td>{moment(permit.company.expirationDate).format("MMM Do YYYY")}</td>
                    <td>{moment(permit.createdAt).format("MMM Do YYYY")}</td>
                    <td>
                      <div className=' flex flex-row space-x-3' >
                        <div className='tooltip hover:cursor-pointer' data-tip="Permit Details" onClick={() => { setCompanyData(permit); openModal(setShowInfoPopup); setPermitType("One Day") }}>
                          <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                          {/* <DetailsModel raf={detailsModelRef} data={companyData} url={apiBaseUrl.replace('/api/v1', '')} approvePermit={approvePermit} closeModal={closeDetailsModal} type={"One Day"} /> */}
                        </div>
                        <div className='tooltip hover:cursor-pointer' data-tip="Approve Permit" onClick={() => { approvePermit(permit.id) }}>
                          <TiTick size={24} color='#4F8E54' className=' bg-[#4f8e5469] rounded-md' />
                        </div>
                        <div className='tooltip hover:cursor-pointer' onClick={() => { setPermitType("One Day"); setPermitId(permit.id); openModal(setShowDenyPopup) }} data-tip="Deny Permit">
                          <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        {/* end not approved companies */}
        <div className=' flex flex-row w-10/12 space-x-5'>
          {/* companies Data */}
          <div className=' w-1/3'>
            <div className=' flex flex-row w-full'>
              <div className=' w-full'>
                <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Recently Joined Users</p>
              </div>
            </div>
            <div className="overflow-x-auto w-full">
              <table className="table table-zebra">
                <thead>
                  <tr>
                    <th></th>
                    <th>Full Name</th>
                    <th>E-mail Address</th>
                    <th>Creation Date</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData && usersData.users.length > 0 ? (
                    usersData.users.slice(0, 10).map((user) => (
                      <tr key={user.id}>
                        <th>{user.id}</th>
                        <td>{user.fullName}</td>
                        <td>{user.email}</td>
                        <td>{moment(user.createdAt).format("MMM Do YYYY")}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No users found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className=' w-1/3'>
            <div className=' flex flex-row w-full'>
              <div className=' w-full'>
                <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Recently Joined Using Form</p>
              </div>
            </div>
            <div className="overflow-x-auto w-full">
              <table className="table table-zebra">
                <thead>
                  <tr>
                    <th></th>
                    <th>Full Name</th>
                    <th>E-mail Address</th>
                    <th>Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData && usersData.formUsers.length > 0 ? (
                    usersData.users.slice(0, 10).map((user) => (
                      <tr key={user.id}>
                        <th>{user.id}</th>
                        <td>{user.fullName}</td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No users found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* companies Data */}
          {/* events data */}
          <div className=' w-1/3'>
            <div className=' flex flex-row w-full'>
              <div className=' w-full'>
                <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Recent Events</p>
              </div>
            </div>
            <div className="overflow-x-auto w-full">
              <table className="table table-zebra">
                <thead>
                  <tr>
                    <th></th>
                    <th>Event Name</th>
                    <th>Event Location</th>
                    <th>Submition Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (eventsData.slice(0, 5).filter((event) => event.name)).map((event) => (
                      <tr key={event.id}>
                        <th>{event.id}</th>
                        <td>{event.name}</td>
                        <td>{event.location}</td>
                        <td>{moment(event.createdAt).format("MMM Do YYYY")}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          {/* events data */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Dasboard

export const DetailsModel = ({ url, data, raf, approvePermit, type }) => {

  function calculateTotalAmount(data) {
    if (!Array.isArray(data)) {
      return 0; // Return 0 or another appropriate value if data is not an array
    }

    const totalAmount = data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.amount;
    }, 0);

    return totalAmount;
  }

  // const feesList = data.event.feesList; // Assuming data.company.feesList is the array
  // const totalAmount = calculateTotalAmount(feesList);

  return (
    <dialog className="modal" ref={raf}>
      {
        data &&
        <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start">
          <h3 className='text-2xl font-semibold text-left'>Company</h3>
          <div className=' divider'></div>
          <div className=' grid grid-cols-3 gap-5'>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Name</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                {data.company.name}
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>License Number</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                {data.company.licenseNumber}
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Issuing Authority</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                {data.company.issuingAuthority}
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Contact Number</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                {data.company.contactNumber}
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Contact Email</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                {data.company.contactEmail}
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Expiration Date</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                {moment(data.company.expirationDate).format("MMMM Do YYYY")}
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Permit Type</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                {type}
              </div>
            </div>
          </div>
          <h3 className='text-2xl font-semibold text-left mt-6'>Event</h3>
          <div className=' divider'></div>
          <div className=' grid grid-cols-3 gap-5'>
            {
              data.event.name &&
              <div className=' flex flex-col space-y-2'>
                <h3 className=' text-left'>Name</h3>
                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                  {data.event.name}
                </div>
              </div>
            }
            {
              data.event.location &&
              <div className=' flex flex-col space-y-2'>
                <h3 className=' text-left'>Location</h3>
                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                  {data.event.location}
                </div>
              </div>
            }
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Activity Type</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                {data.event.activityType}
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Insurance Policy</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                <a href={`${url}${data.event.companyInsurancePolicy}`} target='_blank'>
                  View File
                </a>
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Acknowledge</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                <a href={`${url}${data.event.acknowledge}`} target='_blank'>
                  View File
                </a>
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Implementation Plan</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                <a href={`${url}${data.event.implementationPlan}`} target='_blank'>
                  View File
                </a>
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Company Insurance Policy</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                <a href={`${url}${data.event.companyInsurancePolicy}`} target='_blank'>
                  View File
                </a>
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>International Trademark License</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                <a href={`${url}${data.event.internationalTradeMarkLicense}`} target='_blank'>
                  View File
                </a>
              </div>
            </div>
            <div className=' flex flex-col space-y-2'>
              <h3 className=' text-left'>Risk Managment Draft</h3>
              <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                <a href={`${url}${data.event.riskManagmentDraft}`} target='_blank'>
                  View File
                </a>
              </div>
            </div>
            {
              type === "One Day" &&
              <div className=' flex flex-col space-y-2'>
                <h3 className=' text-left'>Leaders Number</h3>
                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                  {data.leadersNumber}
                </div>
              </div>
            }
            {
              type === "One Day" &&
              <div className=' flex flex-col space-y-2'>
                <h3 className=' text-left'>Assistances Number</h3>
                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                  {data.assistancesNumber}
                </div>
              </div>
            }
          </div>
          <h3 className='text-2xl font-semibold text-left mt-6'>Fees List</h3>
          <div className=' divider'></div>
          <div className=' grid grid-cols-3 gap-5'>
            {
              (data.event.feesList) &&
              (data.event.feesList).map(fee => (
                <div className=' flex flex-col space-y-2' key={fee.id}>
                  <h3 className=' text-left'>Option {fee.id}</h3>
                  <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                    {fee.title + " || " + fee.amount + " AED"}
                  </div>
                </div>
              ))
            }
          </div>
          {/* <h3 className='text-2xl font-semibold text-right mt-6'>Amount To Pay: {totalAmount} <span className=' text-xs'>AED</span></h3> */}
          <div className="modal-action">
            {/* if there is a button, it will close the modal */}
            <div className="btn bg-[#4f8e5469] text-[#4f8e54]" onClick={() => { approvePermit(data.id) }}>Approve</div>
            <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
          </div>
        </form>}
    </dialog>
  )
}